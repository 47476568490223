<template>
  <div class="vip_buy_warp">
    <van-overlay :show="vipDate.show">
      <div class="vip_buy_box">
        <div class="vip_card">
          <svg-icon icon-class="vip_card2"/>
        </div>
        <div class="info">
          <p>你还不是会员哦～</p>
          <p>开通会员即可预览大图</p>
        </div>
        <div class="openVip" @click="goVip">立即开通</div>
        <div class="share" @click="goShare">分享推广</div>
        <div class="close" @click="vipDate.show = false">
          <svg-icon icon-class="close5"/>
        </div>
      </div>
    </van-overlay>
  </div>
</template>
<script>
import {Overlay} from "vant";
import {mapGetters} from "vuex";

export default {
  components: {
    [Overlay.name]: Overlay,
  },
  computed: {
    ...mapGetters({
      vipDate: 'vipDate',
    })
  },
  methods: {
    goVip() {
      this.$router.push('/memberCentre');
      this.vipDate.show = false
    },
    goShare() {
      this.$router.push('/sharePromote');
      this.vipDate.show = false
    }
  }
}
</script>
<style lang="scss" scoped>
.vip_buy_warp {
  /deep/ .van-overlay {
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .vip_buy_box {
    width: 340px;
    height: 320px;
    background: url('../../assets/png/vip_bg.png') no-repeat;
    background-size: cover;
    text-align: center;
    margin-bottom: 10%;
    position: relative;

    .vip_card {
      text-align: center;
      margin-top: -50px;

      > svg {
        font-size: 120px;
      }
    }

    .info {
      color: #425453;
      padding-top: 50px;
      text-align: center;
      line-height: 32px;
      font-size: 14px;

      p:nth-child(2) {
        color: #000000;

      }
    }

    .openVip {
      width: 252px;
      height: 38px;
      text-align: center;
      line-height: 38px;
      background: #ff9f19;
      border-radius: 6px;
      margin: 20px auto;
      font-size: 14px;
      box-shadow: 4px 4px 12px rgba(#ff7527, 0.5);

    }

    .share {
      text-align: center;
      color: #425453;
      display: inline-block;
      padding-top: 10px;
      padding-bottom: 4px;
      border-bottom: 0.5px solid rgba(#425453, 0.6);
    }

    .close {
      position: absolute;
      font-size: 34px;
      bottom: -80px;
      left: 50%;
      margin-left: -17px;
    }
  }

}
</style>

