<template>
  <div class="comment_warp">
    <PullRefresh
      :loading="loading"
      :refreshing="refreshing"
      :finished="finished"
      @onLoad="onLoad"
      @onRefresh="onRefresh"
      :isHigehtMax="true"
      :error="error"
      :disabled="disabled"
    >
      <div v-if="list.length > 0" @click="cleanLever2">
        <div class="comment_item" v-for="(item, index) in list" :key="item.id">
          <div class="comment_item_box">
            <div class="left-avatar" :class="{ isCertifica: item.merchantUser == 1 }">
              <div @click="jumpHomePage(item)" class="img_box">
                <ImgDecypt :src="item.userPortrait" round />
              </div>
              <div class="certification" v-if="item.superUser == 1">
                <Vlogo :vid="item.id" />
              </div>
              <svg-icon class="certificaBorder" iconClass="certificaBorder" v-if="item.merchantUser == 1" />
              <!-- <svg-icon class="certification" iconClass="certification" v-if="item.superUser == 1"></svg-icon> -->
            </div>
            <div :class="['right-content', { divine_comment_bg: item.isGodComment }]">
              <div class="username">
                <h2 class="ellipsis" :class="{ vipName: checkVip(item) }" @click="jumpHomePage(item)">{{ item.userName }}</h2>
                <svg-icon class="user_icon" iconClass="crown" v-if="checkVip(item)"></svg-icon>
                <div class="user_icon" v-for="icon in userIcons(item)" :key="icon.number">
                  <ImgDecypt :src="icon.imgUrl" v-if="icon.isExpire" />
                </div>
              </div>
              <div class="time">上传时间 {{ item.createdAt | timeYMD }}</div>
            </div>
          </div>
          <div class="content_text" @click.stop="reply(item, index)">
            {{ item.content }}
          </div>
          <div class="text_operate">
            <div class="text_operate_left">
              <span class="time">{{ item.createdAt | timeHM }}</span>
            </div>
            <div class="text_operate_right">
              <div class="reply" @click.stop="reply(item, index)">
                <span>回复</span>
              </div>
              <div @click="like(item)">
                <svg-icon :icon-class="item.isLike ? 'tip_like_active' : 'tip_like'"></svg-icon>
                <span v-show="item.likeCount">{{ item.likeCount }}</span>
              </div>
            </div>
          </div>
          <!-- 展开更多-->
          <div class="more" @click="commentClick(item, index)" v-if="item.commCount > 0">
            <span>{{ item.isExpand ? '收起' : '展开' }}{{ item.commCount }}条回复</span>
            <svg-icon icon-class="sanjiao" :class="{ rotate180: item.isExpand }" />
            <span v-if="isExpand"></span>
          </div>
          <!-- 二级评论-->
          <div v-if="item.isExpand" class="lever_box2">
            <div class="comment2" v-for="subItem in item.Info" :key="subItem.id">
              <div class="comment2_box">
                <div class="left-avatar" :class="{ isCertifica: item.merchantUser == 1 }">
                  <div @click="jumpHomePage(subItem)" class="img_box">
                    <ImgDecypt :src="subItem.userPortrait" round />
                  </div>
                  <div class="certification" v-if="subItem.superUser">
                    <Vlogo :vid="subItem.id" />
                  </div>
                  <svg-icon class="certificaBorder" iconClass="certificaBorder" v-if="subItem.merchantUser == 1" />
                  <!-- <svg-icon class="certification" iconClass="certification"
                            v-if="subItem.superUser"></svg-icon> -->
                </div>
                <div :class="['right-content', { divine_comment_bg: subItem.isGodComment }]">
                  <div class="username">
                    <h2 class="ellipsis" :class="{ vipName: checkVip(subItem) }" @click="jumpHomePage(subItem)">
                      {{ subItem.userName }}
                    </h2>
                    <svg-icon class="user_icon" iconClass="crown" v-if="checkVip(item)"></svg-icon>
                    <div class="user_icon" v-for="icon in userIcons(item)" :key="icon.number">
                      <ImgDecypt :src="icon.imgUrl" v-if="icon.isExpire" />
                    </div>
                  </div>
                  <div class="time">上传时间 {{ item.createdAt | timeYMD }}</div>
                </div>
              </div>
              <div class="content_text" @click.stop="reply(item, index)">
                {{ subItem.content }}
              </div>
              <div class="text_operate">
                <div class="text_operate_left">
                  <span class="time">{{ subItem.createdAt | timeHM }}</span>
                </div>
                <div class="text_operate_right" @click="like(subItem)">
                  <svg-icon :icon-class="subItem.isLike ? 'tip_like_active' : 'tip_like'"></svg-icon>
                  <span v-show="subItem.likeCount">{{ subItem.likeCount }}</span>
                </div>
              </div>
            </div>
            <div class="leverLoading" v-if="!item.isLever2Loading">
              <img class="loading" slot="loading" :src="loadingSrc" rel="external nofollow" />
            </div>
            <div class="comment_more_box" @click="lever2More(item, index)">
              <div class="comment_more" v-if="item.lever2hasNext">
                展开更多回复
                <svg-icon icon-class="sanjiao" />
              </div>
              <span v-else>没有更多了</span>
            </div>
          </div>
        </div>
      </div>
      <div class="nodata" v-if="list.length == 0 && !error">暂无评论</div>
    </PullRefresh>
    <!--有蒙层-->
    <van-popup
      v-show="isPopShow"
      v-model="isInputShow"
      position="bottom"
      class="common_input_box"
      @click-overlay="$emit('close')"
      :close-on-click-overlay="false"
    >
      <van-field
        v-model="commentValue"
        :placeholder="placeholder"
        :class="commentValue ? 'vant80' : 'vant100'"
        :autofocus="autofocus"
        class="sendInout"
        @input="changeInput"
        ref="commentInp"
      />
      <div class="send" v-if="commentValue.length > 0" @click.stop="sendMessage">
        <svg-icon icon-class="plane" />
      </div>
    </van-popup>
    <!--一直悬浮在底部 -->
    <div v-show="!isPopShow" class="input-bpx common_input_box">
      <van-field
        v-model="commentValue"
        :placeholder="placeholder"
        :class="commentValue ? 'vant80' : 'vant100'"
        :autofocus="autofocus"
        class="sendInout"
        @input="changeInput"
        ref="commentInp"
      />
      <div class="send" v-if="commentValue.length > 0" @click.stop="sendMessage">发送</div>
    </div>
  </div>
</template>
<script>
import Vlogo from '@/components/Vlogo';
import ImgDecypt from '@/components/ImgDecypt';
import { Field, Popup, Toast } from 'vant';
import { cancleZan, commentSend, queryCommentList, zan, queryCommentListlever2 } from '@/api/user';
import PullRefresh from '@/components/PullRefresh';
import { getBeijinTime } from '@/utils';
import { getMedal } from '@/utils/getConfig';

export default {
  props: {
    isInputShow: {
      type: Boolean,
      default: false, //是否显示input 框
    },
    isPopShow: {
      type: Boolean,
      default: false, //是否需要蒙层
    },
    videoId: {
      //视频ID/帖子ID
      type: String,
      required: true,
      default: '',
    },
    customizeReplay: {
      //是否自定义外层回复 （用于input框在外层组件）
      type: Boolean,
      default: false,
    },
    externalReply: {
      //是否外部回复
      type: Boolean,
      default: false,
    },
    disabled: {
      //是否禁用下拉刷新
      type: Boolean,
      default: false,
    },
    autofocus: {
      //是否自动选中输入框
      autofocus: {
        type: Boolean,
        default: false,
      },
    },
    parData: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  components: {
    Vlogo,
    ImgDecypt,
    [Field.name]: Field,
    [Popup.name]: Popup,
    PullRefresh,
  },
  watch: {
    isInputShow(newV, oldV) {
      if (newV && this.autofocus) {
        this.$nextTick(() => {
          document.querySelector('.sendInout input').focus();
        });
      }
    },
    parData: {
      handler(newV, oldV) {
        if (newV.isReply == false && newV.data.id) {
          this.list.unshift(newV.data);
        }
        if (newV.isReply && newV.data.id) {
          let index = this.list.findIndex((el) => el.id == newV.data.cid);
          this.list[index].Info.unshift(newV.data);
          this.list[index].commCount++;
        }
      },
      deep: true,
    },
  },
  data() {
    return {
      loadingSrc: require('@/assets/gif/loading.gif'),
      isLever2Loading: false,
      isExpand: false,
      placeholder: '留下您精彩的评论吧～',
      commentValue: '',
      list: [],
      InfoLever2: [],
      loading: true,
      refreshing: false,
      finished: false,
      pageNumber: 1,
      pageSize: 20,
      pageNumber2: 1, //二级评论
      pageSize2: 6, //二级评论
      error: false,
      isLever2Reply: false, //是否二级回复
      lever2ReplyParams: {}, //二级评论参数
      lever2hasNext: false,
      maxlength: 120,
      total: 0,
    };
  },
  created() {
    this.getCommentList();
  },
  methods: {
    changeInput() {
      if (this.commentValue.length > this.maxlength) {
        this.commentValue = this.commentValue.slice(0, this.maxlength);
      }
      if (this.commentValue.length === this.maxlength) {
        Toast({ message: '最多只能输入' + this.maxlength + '个字符', position: 'bottom' });
      }
    },
    // 跳转用户主页
    jumpHomePage(item) {
      this.$router.push({ path: '/userHomePage', query: { uid: item.userID } });
      const data = {
        index: -1, //当前评论消息的索引
        show: false, //是否打开评论弹窗
        params: {}, //参数
      };
      this.$store.commit('user/UPDATE_COMMENT', data);
    },
    userIcons(info) {
      return getMedal(info?.awardsExpire || []) || [];
    },
    commentClick(item, index) {
      this.isExpand = !this.isExpand;
      item.isExpand = !item.isExpand;
      if (!item.pageNumber2) {
        item.pageNumber2 = this.pageNumber2;
        item.pageSize2 = this.pageSize2;
      }
      if (item.lever2hasNext === true) {
        item.pageNumber2++;
      }
      if (item.isExpand && (item.lever2hasNext === undefined || item.lever2hasNext === true)) {
        item.isLever2Loading = false;
        this.queryCommentListlever2List(item, index);
      }
    },
    lever2More(item, index) {
      item.pageNumber2++;
      item.isLever2Loading = false;
      this.queryCommentListlever2List(item, index);
    },
    //发送评论
    async sendMessage() {
      let req = {
        content: this.commentValue.trim(),
      };
      if (!this.isLever2Reply) {
        req = Object.assign(req, {
          objID: this.videoId,
          level: 1,
        });
      } else {
        req = Object.assign(req, this.lever2ReplyParams);
      }
      if (!req.content) {
        Toast('评论消息不能为空');
        return;
      }
      try {
        this.$store.commit('app/SET_LOADING', true);
        let res = await this.$Api(commentSend, req);
        this.$store.commit('app/SET_LOADING', false);
        if (res.code === 200) {
          this.updateNum();
          this.total++;
          this.$emit('getTotal', this.total);
          //一级评论
          if (!this.isLever2Reply) {
            this.list.unshift(res.data);
            Toast('评论成功');
            this.commentValue = '';
            return;
          }
          //二级评论
          let index = this.list.findIndex((el) => el.id == req.cid);
          if (this.list[index].isExpand != undefined || this.list[index].Info.length === 0) {
            this.list[index].Info.unshift(res.data);
          }
          this.list[index].commCount++;
          Toast('评论成功');
          this.commentValue = '';
          this.isLever2Reply = false;
          this.placeholder = '留下您精彩的评论吧～';
          return;
        }
        Toast(res.tip || '评论失败');
      } catch (error) {
        this.$store.commit('app/SET_LOADING', false);
        Toast('评论失败');
      }
    },
    // 获取评论
    async getCommentList(type) {
      let req = {
        objID: this.videoId,
        curTime: getBeijinTime(),
        pageNumber: String(this.pageNumber),
        pageSize: String(this.pageSize),
      };
      let res = await this.$Api(queryCommentList, req);
      this.loading = false;
      this.refreshing = false;
      if (res.code === 200) {
        let list = res.data.list || [];
        this.total = res.data.total;
        this.$emit('getTotal', this.total);
        if (type === 'refresh') {
          this.list = list;
        } else {
          this.list = this.list.concat(list);
        }
        if (!res.data.hasNext) {
          this.finished = true;
          return;
        }
        return;
      }
      this.error = true;
    },
    //获取二级评论
    async queryCommentListlever2List(item, index) {
      let req = {
        objID: this.videoId,
        cmtId: item.id,
        curTime: getBeijinTime(),
        fstID: item.Info[0].id,
        pageNumber: String(item.pageNumber2),
        pageSize: String(item.pageSize2),
      };
      let res = await this.$Api(queryCommentListlever2, req);
      this.$set(item, 'isLever2Loading', true);
      if (res.code === 200) {
        let list = res.data.list || [];
        this.list[index].Info.push(...list);
        if (res.data.hasNext) {
          this.$set(item, 'lever2hasNext', true);
        } else {
          this.$set(item, 'lever2hasNext', false);
        }
      }
    },
    //回复
    async reply(item, index) {
      if (this.customizeReplay && !this.externalReply) {
        this.$nextTick(() => {
          this.$refs.commentInp?.focus();
        });
        this.$emit('parReply', { data: item, index: index });
        return;
      }
      this.$nextTick(() => {
        this.$refs.commentInp?.focus();
      });
      if (this.externalReply) {
        this.$emit('parReply');
      }
      this.lever2ReplyParams = {
        objID: item.objID,
        level: 2,
        userID: item.userID,
        cid: item.id,
      };
      this.isLever2Reply = true;
      this.placeholder = `回复：${item.userName}`;
    },
    //清空二级评论值
    cleanLever2() {
      this.placeholder = '留下您精彩的评论吧～';
      this.isLever2Reply = false;
    },
    onLoad() {
      this.loading = true;
      this.pageNumber++;
      this.getCommentList();
    },
    onRefresh() {
      this.pageNumber = 1;
      this.refreshing = true;
      this.finished = false;
      this.loading = true;
      this.getCommentList('refresh');
    },
    //点赞
    async like(item) {
      let isLike = item.isLike;
      let id = item.id;
      let req = {
        type: 'comment',
        objID: id,
      };
      try {
        if (isLike) {
          let res = await this.$Api(cancleZan, req);
          if (res.code === 200) {
            item.isLike = false;
            item.likeCount--;
            Toast('取消点赞');
            return;
          }
          Toast(res.tip || '取消失败');
        } else {
          let res = await this.$Api(zan, req);
          if (res.code === 200) {
            item.isLike = true;
            item.likeCount++;
            Toast('点赞成功');
            return;
          }
          Toast(res.tip || '点赞失败');
        }
      } catch (e) {
        Toast('操作失败');
      }
    },
    // 校验是否会员、会员时间是否到期
    checkVip(item) {
      let todayTime = new Date().getTime();
      let effectiveTime = new Date(item.vipExpireDate).getTime();
      if (item && item.vipLevel > 0 && todayTime < effectiveTime) {
        return true;
      }
      return false;
    },
    // 评论成功发送通知各个页面修改评论数量
    updateNum() {
      //帖子详情
      this.$emit('success');
      //用于最外层评论
      let path = this.$route.path;
      if (path === '/community/recommend') {
        this.$store.commit('user/UPDATE_COMMUNITY_R', { isSuccess: true, id: this.videoId });
        return;
      }
      if (path === '/community/foucus') {
        this.$store.commit('user/UPDATE_COMMUNITY_F', { isSuccess: true, id: this.videoId });
        return;
      }
      if (path === '/tags/video') {
        this.$store.commit('user/UPDATE_Video', { isSuccess: true, id: this.videoId });
        return;
      }

      if (path === '/tags/graphics') {
        this.$store.commit('user/UPDATE_Graphics', { isSuccess: true, id: this.videoId });
        return;
      }
      if (path === '/shortVideo') {
        this.$store.commit('user/UPDATE_SHORT_Video', { isSuccess: true, id: this.videoId });
        return;
      }
      if (path === '/communityVideo') {
        this.$store.commit('user/UPDATE_SHORT_Video_Community', { isSuccess: true, id: this.videoId });
        return;
      }
      if (path === '/myCollection/collecPostList') {
        this.$store.commit('user/UPDATE_Collec_Graphics', { isSuccess: true, id: this.videoId });
        return;
      }
      if (path === '/citys/graphics') {
        this.$store.commit('user/UPDATE_CITY_Graphics', { isSuccess: true, id: this.videoId });
        return;
      }
    },
  },
};
</script>
<style scoped lang="scss">
.comment_warp {
  padding-bottom: 50px;
  height: 100%;
  .rotate180 {
    transform: rotate(180deg);
  }
  .vipName {
    color: #ff678f !important;
  }

  .nodata {
    text-align: center;
    color: #969799;
  }

  .comment_item {
    padding-top: 20px;
    .comment_item_box {
      display: flex;
    }
    .content_text {
      padding-top: 12px;
      font-size: 14px;
      color: #444444;
    }
    .text_operate {
      display: flex;
      justify-content: space-between;
      font-size: 12px;
      margin-top: 4px;
      padding-bottom: 8px;

      .text_operate_left {
        display: flex;
        color: #999999;
        line-height: 20px;

        .time {
          display: inline-block;
          padding-right: 15px;
          font-size: 14px;
        }
      }

      .text_operate_right {
        display: flex;
        align-items: center;
        .reply span {
          padding-right: 20px;
          color: #999999;
          font-size: 13px;
        }
        svg {
          font-size: 16px !important;
          padding-right: 5px;
        }
      }
    }

    .text_operate {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 12px;
      margin-top: 11px;
      padding-bottom: 8px;

      .text_operate_left {
        display: flex;
        color: #999999;
        line-height: 20px;

        .time {
          display: inline-block;
          padding-right: 15px;
          font-size: 14px;
        }
      }

      .text_operate_right {
        svg {
          font-size: 16px;
          padding-right: 5px;
        }
      }
    }
    .left-avatar {
      width: 42px;
      height: 42px;
      margin-right: 16px;
      flex-shrink: 0;
      position: relative;

      .img_box {
        height: 100%;
      }

      .certification {
        width: 14.1px;
        height: 14.2px;
        position: absolute;
        right: 0;
        bottom: 0;
        overflow: hidden;
        border-radius: 50%;
        -webkit-backface-visibility: hidden;
        -moz-backface-visibility: hidden;
        -webkit-transform: translate3d(0, 0, 0);
        -moz-transform: translate3d(0, 0, 0);
      }

      .certificaBorder {
        position: absolute;
        top: -8.2px;
        width: 100%;
        font-size: 25px;
        z-index: 1;
      }
    }

    .isCertifica {
      border-radius: 50%;
      border: 1.5px solid #d82d0b;
    }

    .divine_comment_bg {
      background: url('../../assets/png/divine_comment.png') no-repeat right 25% bottom 50%;
      background-size: 60px;
    }
    .right-content {
      width: 100%;
      overflow: hidden;

      .time {
        padding-top: 2px;
        color: #666666;
        font-size: 12px;
      }

      .username {
        display: flex;
        align-items: center;

        .user_icon {
          width: 17px;
          height: 17px;
          padding-left: 5px;
        }

        h2 {
          max-width: 250px;
          font-size: 16px;
          font-weight: 600;
          color: #333333;
        }

        padding-bottom: 4px;
      }

      .text {
        color: #e7e7e7;
        line-height: 22px;
        font-size: 17px;
        margin-right: 30px;
      }
    }

    .comment2 {
      padding-top: 16px;
      .comment2_box {
        display: flex;
        align-items: center;
        .left-avatar {
          width: 32px;
          height: 32px;
        }
      }
    }
    .more {
      font-size: 14px;
      color: #ff678f;
      display: flex;
      align-items: center;
      justify-content: center;

      > svg {
        font-size: 18px;
      }
    }

    .lever_box2 {
      padding-left: 44px;
      .leverLoading {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 20px;
          text-align: center;
        }
      }
    }
    .comment_more_box {
      padding-top: 10px;
      color: #ff678f;
      font-size: 14px;
      text-align: center;
      svg {
        font-size: 18px;
      }

      span {
        font-size: 12px;
        color: #999999;
      }
    }
  }

  .input-bpx {
    position: fixed;
    bottom: 0;
    z-index: 11;
    margin-left: -16px;
  }

  .common_input_box {
    width: 100%;
    height: 50px;
    background: #f2f3f5;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    justify-content: space-between;

    /deep/ .van-cell::after {
      border: none;
    }

    /deep/ .van-field__control {
      font-size: 16px;
    }

    /deep/ .van-field {
      background: #ffffff;
      border-radius: 18.5px;
      margin: 0 16px;
      transition: all 0.5s linear;

      input {
        height: 37px;
        padding: 0 16px;
        background: #ffffff;
        border: none;
        outline: none !important;
        display: block;
        border-radius: 18.5px;
      }
    }

    ::placeholder {
      color: #999999;
      font-size: 14px;
    }

    .send {
      width: 60px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      background-color: #ffffff;
      border-radius: 20px;
      margin-right: 12px;
      color: #ff678f;

      svg {
        font-size: 20px;
      }
    }

    .vant100 {
      width: 100%;
      padding: 0;
    }

    .vant80 {
      width: 80%;
      padding: 0;
    }
  }
}
</style>
