<template>
  <div class="img_Decypt">
    <lazy-component :class="['laz_com', { borderRadius50: round != null }]">
      <AnimationImg :itemData="itemData" @load="onload" :src="src" :isNeedDecypt="isNeedDecypt" :round="round" />
      <slot />
    </lazy-component>
  </div>
</template>
<script>
import AnimationImg from '@/components/AnimationImg';

export default {
  name: 'imgDecypt',
  props: ['src', 'isNeedDecypt', 'round', 'itemData'],
  components: {
    AnimationImg,
  },
  methods: {
    onload() {
      this.$emit('load');
    },
  },
};
</script>
<style scoped lang="scss">
.img_Decypt {
  height: 100%;

  .laz_com {
    width: 100%;
    height: 100%;
    background-color: #e6e7e9;
  }
  .borderRadius50 {
    border-radius: 50%;
  }
}
</style>
