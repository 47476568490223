<template>
  <div class="refreshing_warp">
    <van-pull-refresh
      v-model="refreshingc"
      @refresh="onRefresh"
      :disabled="disabled"
      success-text="刷新成功"
      :class="[{ 'my-refresh': isHigehtMax, none: refreshing }, className]"
    >
      <AdvSwiper :advList="advList" v-if="advList.length" :hasPagination="hasAdvPagination" :height="height" class="adv" />
      <van-list
        v-if="!isNoData"
        v-model="loadingc"
        :finished="finishedc"
        finished-text="没有更多数据了"
        @load="onLoad"
        :offset="10"
        :error.sync="errorc"
        :immediate-check="false"
        error-text="请求失败，点击重新加载"
      >
        <OricilegeInfo v-if="showOricilegeInfo" />
        <van-notice-bar :text="marqueeText" background="#1e1e1e" color="#ffffff" speed="40" v-if="marqueeText">
          <template slot="left-icon">
            <div class="left_icon">
              <svg-icon iconClass="notice"></svg-icon>
              <span>活动公告：</span>
            </div>
          </template>
        </van-notice-bar>
        <slot></slot>
      </van-list>
      <NoData v-if="isNoData" />
    </van-pull-refresh>
  </div>
</template>
<script>
import { List, NoticeBar, PullRefresh } from 'vant';
import NoData from '@/components/NoData';
import AdvSwiper from '@/components/AdvSwiper';
import OricilegeInfo from '@/views/movies/widget/oricilegeInfo';
export default {
  props: {
    hasAdvPagination: {
      type: Boolean,
      default: false,
    },
    advList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    className: {
      type: String,
      default: '',
    },
    marqueeText: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false, //是否禁止下拉刷新 默认为false
    },
    refreshing: {
      type: Boolean,
      default: false, //下拉刷新loading
    },
    loading: {
      type: Boolean,
      default: false, //上滑刷新loading
    },
    finished: {
      type: Boolean,
      default: false, //列表数据是否记载完毕
    },
    isNoData: {
      type: Boolean,
      default: false, //数据是否为空
    },
    showOricilegeInfo: {
      type: Boolean,
      default: false, //是否显示会员权益
    },
    error: {
      type: Boolean,
      default: false, //请求错误
    },
    isHigehtMax: {
      /**
       * 占满父级100%
       * 组件内部 overflow-y:auto
       * 此参数设置为true的话，父级不要设置overflow-y:auto
       */
      type: Boolean,
      default: false,
    },
    height: {
      type: String,
      default: '150px',
    },
  },
  components: {
    [List.name]: List,
    [PullRefresh.name]: PullRefresh,
    NoData,
    AdvSwiper: AdvSwiper,
    [NoticeBar.name]: NoticeBar,
    OricilegeInfo,
  },
  data() {
    return {
      errorc: false,
    };
  },
  computed: {
    loadingc: {
      get() {
        return this.loading;
      },
      set() {
        return this.loading;
      },
    },
    finishedc: {
      get() {
        return this.finished;
      },
      set() {
        return this.finished;
      },
    },
    refreshingc: {
      get() {
        return this.refreshing;
      },
      set() {
        return this.refreshing;
      },
    },
  },
  watch: {
    error: function (val) {
      this.errorc = val;
    },
  },
  methods: {
    onLoad() {
      this.$emit('onLoad');
    },
    onRefresh() {
      this.$emit('onRefresh');
    },
  },
};
</script>
<style scoped lang="scss">
/deep/ .van-loading__circular {
  display: none;
}

.adv {
  margin-bottom: 8px;
  padding: 10px 12px;
  /deep/ .swiper-container {
    overflow: hidden;
    border-radius: 6px;
    img {
      height: 100%;
      border-radius: 6px;
    }
  }
  /deep/ img {
    height: 100%;
    object-fit: fill;
  }
}

.none {
  /deep/ .van-list {
    .van-loading {
      display: none;
    }
  }
}

/deep/ .van-loading__spinner {
  background: url('../../assets/gif/loading.gif');
  width: 25px !important;
  height: 25px !important;
  background-size: cover;
  animation: none;
}

.refreshing_warp {
  height: 100%;
  .my-refresh {
    height: 100%;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }

  .loading_warp {
    display: flex;
    align-items: center;
    justify-content: center;

    .loading_box {
      width: 200px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;

      .loading {
        width: 25px;
      }

      .loading_text {
        padding-left: 5px;
      }
    }
  }
}
</style>
