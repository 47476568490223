<template>
  <div class="globalLoding">
    <img class="loadingImg" :src="loadingImg" alt="">
  </div>
</template>

<script>
export default {
    data() {
        return {
            loadingImg: require('../../assets/gif/loading.gif')
        }
    }
}
</script>

<style lang="scss" scoped>
.globalLoding {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background:rgba(0,0,0,0.5);
    z-index:99999;
    display: flex;
    align-items: center;
    justify-content: center;
    .loadingImg {
        width: 40px;
        height: 40px;
    }
}
</style>