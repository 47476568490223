<template>
  <div class="pop_comment pcm-pop">
    <van-popup v-model="commentData.show" position="bottom" class="my_pop" round closeable :close-on-click-overlay="true">
      <div class="content">
        <div class="header">
          <div class="title">
            <span>
              讨论
              <span class="total">{{ total }}</span>
            </span>
            <span class="num"></span>
          </div>
        </div>
        <Comment :videoId="commentData.params.videoId" class="comment" :key="commentData.params.videoId" ref="commentRef" @getTotal="getTotal" />
      </div>
    </van-popup>
  </div>
</template>
<script>
import { Popup } from 'vant';
import Comment from '@/components/Comment';
import { mapGetters } from 'vuex';

export default {
  components: {
    [Popup.name]: Popup,
    Comment,
  },
  data() {
    return {
      total: 0,
    };
  },
  computed: {
    ...mapGetters({
      commentData: 'commentData',
    }),
  },
  methods: {
    getTotal(total) {
      this.total = total;
    },
  },
};
</script>
<style lang="scss" scoped>
.pcm-pop {
  /deep/ .van-popup--bottom {
    left: auto !important;
    overflow-y: hidden;
  }

  .my_pop {
    max-width: 540px;
  }

  /deep/ .common_input_box {
    max-width: 540px;
  }
}

.pop_comment {
  //height: 100%;
  .content {
    height: calc(100% - 92px);
  }
  .header {
    display: flex;
    justify-content: space-between;
    padding: 11px 16px 15px;
    font-size: 16px;
    height: 15px;
    .total {
      font-size: 12px;
      padding: 0 4px;
      color: #ff678f;
    }
  }

  .my_pop {
    height: 65%;
    background: #ffffff;

    .comment {
      padding: 0 16px;
      margin-bottom: 60px;
    }
  }
}
</style>
