import {
    getMoudles
} from "@/api/movies";
import {
    Api
} from "@/utils";

const state = {
    moduleList: []
}

const mutations = {
    SET_MOUDLES: (state, list) => {
        state.moduleList = list || []
    }
}

const actions = {
    async setMoudles({
        commit
    }) {
        let res = await Api(getMoudles, undefined);
        if (res.code === 200) {
            commit('SET_MOUDLES', res.data)
        }
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}