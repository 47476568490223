import Vue from 'vue';
import App from './App.vue';
import router from './router'; //路由
import store from './store'; //引入store
import '@/assets/css/reset.css'; //初始化css
import '@/assets/css/common.css'; //公用css
import VueTouch from 'vue-touch'; //监听手势
import './permission';
import { Lazyload } from 'vant';
import 'lib-flexible';
import '@/icons';
import { Api } from '@/utils';
import VueClipboard from 'vue-clipboard2';
import * as filters from './assets/js/filters';
import * as devtoolsDetector from 'devtools-detector';
const getQuueryVariable = (val) => {
  const query = window.location.search.substring(1);
  const vars = query.split('&');
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=');
    if (pair[0] == val) {
      return pair[1];
    }
  }
  return false;
};
devtoolsDetector.addListener((isOpen) => {
  if (isOpen) {
    window.location.replace('https://www.baidu.com');
  }
});
const devTest = getQuueryVariable('devTest') || sessionStorage.getItem('devTest');
if (getQuueryVariable('devTest')) {
  sessionStorage.setItem('devTest', 'devTest');
}
if (!devTest && process.env.NODE_ENV == 'production') {
  devtoolsDetector.launch();
}
import { Tab, Tabs, Dialog, Popup, NoticeBar, Swipe, SwipeItem, Overlay, Loading } from 'vant';

Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Dialog);
Vue.use(Popup);
Vue.use(NoticeBar);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Overlay);
Vue.use(Loading);

Vue.config.productionTip = false;
Vue.prototype.$Api = Api;
Vue.use(VueClipboard);
Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key]); //插入过滤器名和对应方法
});

Vue.use(Lazyload, {
  lazyComponent: true,
});
Vue.use(VueTouch, {
  name: 'v-touch',
});
new Vue({
  router,
  store,
  render: (h) => h(App),
  beforeCreate() {
    Vue.prototype.$bus = this;
  },
}).$mount('#app');
