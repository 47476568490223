<template>
  <div :id="vid" class="lottie" ></div>
</template>

<script>
  import lottie from "lottie-web"
  import animationData from "@/assets/json/v-logo.json";
  export default {
    props: {
      vid: {
        required: true,
      }
    },
    mounted() {
      this.svgFun();
    },
    methods: {
      svgFun() {
        let params = {
          container: document.getElementById(this.vid),
          renderer: "svg",
          loop: true,
          autoplay: true,
          animationData: animationData,
          // path: "/",
        };
        let anim;
        anim = lottie.loadAnimation(params);
      },
    }
  }
</script>

<style lang="scss" scoped>
  .lottie {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
</style>