<template>
  <van-popup class="dialog" v-model="value" :close-on-click-overlay="false">
    <div class="dialogContent">
      <div class="dialogTitle">
        <span>温馨提示</span>
      </div>
      <div class="dialogText">
        <!--                <span>你还不是<span class="vip">VIP</span>无法修改简介</span>-->
        <span>{{ content }}</span>
      </div>
      <div class="btnGroup">
        <img
          @click="
            close();
            $router.push('/memberCentre?t=vip');
          "
          class="btn toBinding"
          src="@/assets/png/tobuyVip.png"
        />
      </div>
    </div>
    <div class="closeBtn" @click="close">
      <img src="@/assets/png/promptClose.png" />
    </div>
  </van-popup>
</template>

<script>
export default {
  name: 'PromptPopup',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    content: {
      type: String,
      default: '',
    },
  },
  methods: {
    close() {
      this.$emit('onClose', false);
    },
  },
};
</script>

<style scoped lang="scss">
.dialog {
  height: 4.43rem;
  border-radius: 0.2rem;
  background: none;

  .dialogContent {
    background-color: #ffffff;
    width: 7.04rem;
    height: 3.52rem;
    border-radius: 0.2rem;

    .dialogTitle {
      font-size: 20px;
      color: rgb(132, 132, 132);
      display: flex;
      justify-content: center;
      padding-top: 0.3rem;
    }

    .dialogText {
      font-size: 0.38rem;
      color: rgb(100, 100, 100);
      padding-top: 16px;
      font-weight: bold;
      text-align: center;

      .vip {
        font-size: 0.45rem;
        color: #ff5c84;
      }
    }

    .btnGroup {
      width: 100%;
      display: flex;
      position: absolute;
      top: 2.25rem;
      display: flex;
      justify-content: center;

      .toBinding {
        //margin-left: 0.76rem;
      }

      .toPrepaid {
        margin-left: 0.22rem;
      }

      .btn {
        width: 180px;
      }
    }
  }

  .closeBtn {
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    z-index: 99999;

    img {
      width: 0.71rem;
      height: 0.71rem;
    }
  }
}
</style>
