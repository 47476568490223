import axios from "@/utils/request";

// 获取影视块接口
export function getMoudles(data) {
    return axios.get(`modules/list`, {params: data});
}

// 查询模块数据列表
export function queryModulsList(data) {
    return axios.get(`vid/module/` + data.id, {params: data.data})
}

//获取影视跑马灯
export function getAnnounce(data) {
    return axios.get(`modules/announce`, {params: data});
}

// 获取具体某个专题下的具体视频
export function getSection(data) {
    return axios.get(`vid/section`, {params: data})
}

// 提现跑马灯
export function getWithdrawalMarquee(data) {
    return axios.get(`modules/exchange_announce`, {params: data})
}