<template>
  <div class="privilegeInfo">
    <!--user-->
    <!-- <div class="user_box">
      <div class="user">
        <div class="user_left">
          <div class="avatar">
            <ImgDecypt :src="userInfo.portrait" round :key="userInfo.portrait" />
          </div>
          <div class="userInfo">
            <h2>
              {{ userInfo.name || '未知' }}
              <svg-icon v-if="!isVip && userInfo.vipLevel == 0" icon-class="unVip" />
              <svg-icon v-else-if="isVip && userInfo.vipLevel == 1" icon-class="vip" />
              <svg-icon v-else-if="isVip && userInfo.vipLevel == 2" icon-class="svip" />
            </h2>

            <p v-if="!isVip">当前未开通VIP</p>
            <p v-else class="vip">{{ formatDate() }}</p>
          </div>
        </div>
        <div class="vip_right" v-if="!isVip">
          <span @click="$router.push('/memberCentre?t=vip')">立即开通</span>
        </div>

        <div class="vip_right" v-if="isVip && userInfo.vipLevel < 2">
          <span @click="$router.push('/memberCentre?t=vip')">立即续费</span>
        </div>
      </div>
      <div class="activity" v-if="countdownSecInfo.countdownSec" @click="$router.push('/memberCentre?t=vip')">
        {{ countdownSecInfo.copywriting || '新人专享倒计时' }}
        {{ countdownSecInfo.timeStr }}
      </div>
    </div> -->
    <!--特权-->
    <!-- <div class="privilege" v-if="list.length">
      <svg-icon icon-class="privilege" />
      <div class="privilege_content oepnX">
        <div class="privilege_item" v-for="item in list" :key="item.id">
          <div class="privilege_item_bg">
            <ImgDecypt :src="item.img" round class="icon" />
          </div>
          <span>{{ item.privilegeName }}</span>
        </div>
      </div>
    </div> -->

    <AdvSwiper :advList="advList" v-if="advList.length" height="150px" class="adv" />
  </div>
</template>

<script>
import AdvSwiper from '@/components/AdvSwiper';
import { mapGetters, mapState } from 'vuex';
import { getVipProductBenefits } from '@/utils/getConfig';
import { AdType, getAdItem } from '@/utils/getConfig';
import { Popover } from 'vant';
export default {
  name: 'oricilegeInfo',
  components: {
    AdvSwiper: AdvSwiper,
    [Popover.name]: Popover,
  },
  data() {
    return {
      list: [],
      showPopover: false,
      actions: [
        {
          text: '图文',
          icon: require('@/assets/png/graphics.png'),
          type: 'graphics',
        },
        {
          text: '视频',
          icon: require('@/assets/png/video.png'),
          type: 'video',
        },
      ],
    };
  },
  // computed: {
  //   ...mapGetters({
  //     userInfo: 'userInfo',
  //   }),
  //   ...mapState({
  //     countdownSecInfo: (state) => state.user.countdownSecInfo,
  //   }),
  //   isVip() {
  //     let vipExpireDate = this.userInfo?.vipExpireDate;
  //     if ((this.userInfo.isVip && this.userInfo.vipLevel > 0) || (this.checkTime(vipExpireDate) && this.userInfo.isVip)) {
  //       return true;
  //     }
  //     return false;
  //   },
  // },
  created() {
    this.advList = getAdItem(AdType.MOVIES_DOMESTIC);
    // this.list = getVipProductBenefits();
    // this.formatDate();
  },
  methods: {
    // 显示vip类型
    showVipType(level) {
      let vipStr = '';
      if (level > 1) {
        vipStr = '超级会员';
      } else {
        vipStr = '普通会员';
      }
      return vipStr;
    },
    // 校验时间
    checkTime(timer) {
      let todayTime = new Date().getTime();
      let effectiveTime = new Date(timer).getTime();
      if (timer && todayTime < effectiveTime) {
        return true;
      }
      return false;
    },
    // 日期
    formatDate() {
      let vipExpireDate = this.userInfo?.vipExpireDate;
      if (!vipExpireDate) return '';
      let level = this.userInfo?.vipLevel;
      let dateago = new Date(vipExpireDate);
      let dateStr = dateago.getFullYear() + '/' + (dateago.getMonth() + 1) + '/' + dateago.getDate();
      return this.showVipType(level) + '  到期:' + dateStr;
    },
    selectPopover(action) {
      if (action.type === 'graphics') {
        this.$router.push('/uploadGraphics');
        return;
      }
      if (action.type === 'video') {
        this.$router.push('/uploadVIdeo');
        return;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.privilegeInfo {
  background: #f6f6f6;
  .info_top {
    background: #ffffff;
    padding: 6px 10px 15px 10px;
    border-bottom: 0.7px solid #e0e0e0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /deep/ .van-notice-bar {
      flex: 1;
      height: 20px;
      line-height: 20px;
      background: #f6f6f6;
      padding: 0 5px;
      .van-notice-bar__content {
        width: 100%;
        height: 100%;
      }
    }
    .redEnvelope {
      font-size: 20px;
    }
    .add {
      padding-left: 17.5px;
      svg {
        font-size: 25px;
      }
    }
    .search {
      flex: 1;
      background: #f6f6f6;
      margin: 0 20px 0 6.5px;
      height: 32px;
      border-radius: 16px;
      display: flex;
      align-items: center;
      padding-left: 12px;
      span {
        padding-left: 3px;
        font-size: 12px;
        color: #999999;
      }
    }
    .RecruitUP {
      width: 75.5px;
      height: 15px;
    }
  }
  .adv {
    margin: 10px;
    /deep/ .swiper-container {
      height: 83px;
    }
    /deep/ .swiperSlide {
      background: #00000000;
    }
    /deep/ .adv-swiper-slide {
      height: 150px !important;
    }
    /deep/ img {
      height: 100%;
      object-fit: fill;
      border-radius: 10px;
    }
  }

  .activity {
    width: 90%;
    margin: 8px 10px 0 10px;
    border-radius: 40px; /* 圆角属性测试 */
    border: 1.5px solid #ffffff;
    padding: 6px 10px;
    text-align: center;
    font-size: 16px;
    color: #ffffff;
  }

  .user_box {
    border-radius: 9px;
    background: url('../../../assets/png/vip_m_bg.png') no-repeat;
    background-position: right;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 7.5px 12px;
    .user {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .user_left {
        display: flex;
        align-items: center;

        .avatar {
          width: 63px;
          height: 63px;
          border: 1px solid #ffffff !important;
          border-radius: 50%;
          flex-shrink: 0;
        }

        .userInfo {
          display: flex;
          padding-left: 7px;
          flex-direction: column;
          justify-content: space-between;
          color: #ffffff;

          h2 {
            font-size: 20px;
            max-width: 180px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            padding-bottom: 4px;
            display: flex;
            align-items: center;
            height: 24px;
            svg {
              padding-left: 5px;

              font-size: 50px;
            }
          }

          p {
            font-size: 13px;
            color: #ffffff;
          }
        }
      }

      .vip_right {
        width: 88px;
        height: 29px;
        background: #fffaf9;
        border-radius: 25px;
        text-align: center;
        line-height: 29px;
        font-size: 14px;
        color: #333333;
        font-weight: 300;
      }
    }
  }

  .privilege {
    background: url('../../../assets/png/privilege_bg.png');
    background-size: contain;
    margin-top: 12.4px;
    svg {
      width: 78px;
      height: 28px;
    }

    .privilege_content {
      overflow-x: scroll;
      white-space: nowrap;
      -webkit-overflow-scrolling: touch;
      .privilege_item {
        text-align: center;
        display: inline-block;
        padding-right: 20px;
        color: #686869;

        .privilege_item_bg {
          width: 40px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 auto 5px;

          .icon {
            width: 40px;
            height: 40px;
          }
        }
      }

      .privilege_item:last-child {
        padding-right: 10px;
      }
    }
  }
}
</style>
