import {Api} from "@/utils";
import {queryHotTagList} from "@/api/community";

const state = {
    tags: [],
    refreshSta: false, // 社区刷新状态
    commnityTag:[], // 社区标签
}

const mutations = {
    // 修改社区刷新状态
    CHANGE_REFRESHSTA: (state, status) => {
        state.refreshSta = status
    },
    //添加标签
    ADD_TAGS: (state, list) => {
        state.tags = list
    },
    //删除全部标签
    CLEARALL_TAGS: (state) => {
        state.tags = []
    },
    //删除指定标签
    DELITEM_TAG: (state, name) => {
        let index = state.tags.findIndex(n => n.name == name);
        state.tags.splice(index, 1)
    },
    SAVE_COMMNITY_TAG:(state,tags) =>{
        state.commnityTag = tags;
    }

}

const actions = {
    async setCommintyTag({commit}) {
        let res = await Api(queryHotTagList);
        if (res.code === 200) {
            let data = res.data?.community;
            data.unshift({id:"最新",name:"最新"})
            commit('SAVE_COMMNITY_TAG', res.data?.community)
        }
    }
}


export default {
    namespaced: true,
    state,
    mutations,
    actions
}
