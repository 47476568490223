<template>
  <div class="nodata_box">
    <img :src="img" alt="" class="icon" />
    <p>暂无数据</p>
  </div>
</template>
<script>
export default {
  data() {
    return {
      img: require('@/assets/png/nodata.png'),
    };
  },
};
</script>
<style scoped lang="scss">
.nodata_box {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 100px;
  display: flex;
  color: #aba9a9;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .icon {
    width: 228px;
  }
}
</style>
